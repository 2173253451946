<template>
    <div class='card-container'>
        <div class="top-img-box">
            <NuxtImgC width="251px" :src="props.card?.image_id" alt="" class="top-img" :fallback="fallbackImg" sizes="261px" />
        </div>
        <div class="category" v-if="props.card.category?.name">{{ props.card.category.name }}</div>
		<ClientOnly >
			<template #fallback></template>		  
			<img @click.stop="wishWrapper('remove')" v-if="isLoggedIn && wish_ids.includes(props.card.id)" width="30px" height="30px" class="favorite-img" src="assets/images/svg/product/favorite-full.svg" />
			<img @click.stop="wishWrapper('add')" v-if="isLoggedIn && !(wish_ids.includes(props.card.id))" width="30px" height="30px" class="favorite-img" src="assets/images/svg/product/favorite.svg" />
		</ClientOnly>
        <div class="card-contents">
            <span class="title">{{ props.card.name }}</span>
            <div class="card-info">
                <span v-if="!card.type?.payment" class="count">{{ `${props.card.quantity} ${$t('product.in-stock')}` }}</span>
                <span class="extra-info annotation-container">{{ props.card.description }}</span>
                <div class="price">
                    <span class="regular-price">{{ card.type?.payment ? $t('interface.from') : '' }} {{ getCurrentCurrencyString(props.card.discount_price ?? props.card.price) }}</span>
                    <span v-if="props.card.discount_price > 0" class="discount-price">{{ getCurrentCurrencyString(props.card.price) }}</span>
                </div>
                <button class="btn" @click.stop="openPopupDefinedByLoginState">{{ $t('product.buy') }}</button>
            </div>
        </div>
        <NuxtLinkLocale @click="ecommercePushHandle" class="card-container-link" :to="`/products/${props.card.slug}`"></NuxtLinkLocale>
    </div>
</template>
<script lang='ts' setup>
import type { productData } from '~/composables/products/types/productTypes';
import { useFastPayModal, useUserStore } from '#imports';
import { useproductstore } from '#imports';
import { useWish } from '~/composables/wish/useWish';

const emits = defineEmits<{
	buyClick:[]
}>()

const productStore = useproductstore();
const { openDeleteFromWishPopup } = useWishDeleteModal()
const { addToWish, removeFromWish } = useWish()

const { getWishIds } = productStore
const { wish_ids } = storeToRefs(productStore);
const $route = useRoute()
const wishWrapper = (key: 'add' | 'remove') => {
    if (key === 'add') {
        addToWish(props.card.id).then(() => {
            getWishIds();
        })
    }
    else {
        if ($route.fullPath === '/profile/favorites') {
            openDeleteFromWishPopup(props.card.id)
            return;
        }
        removeFromWish(props.card.id).then(() => {
            getWishIds();
        })
    }
}

const { getCurrentCurrencyString } = useCurrencyHelpers()

const userStor = useUserStore();
const { isLoggedIn } = storeToRefs(userStor)
const props = defineProps<{ card: productData }>();

const { locale } = useI18n()
const { openFastPayPopup } = useFastPayModal()
const { openPopup } = usePayModal()
const { openPopup: openSteamPayPopup } = useSteamPayModal()
const { ecommercePush } = useYm()
const openPopupDefinedByLoginState = () => {
	emits('buyClick')
    if (!isLoggedIn.value) return openFastPayPopup(props.card)
    if (props.card.type.payment) return openSteamPayPopup(props.card)
    openPopup(props.card)
}
const fallbackImg = useAsset('/assets/images/png/default-imgs/product.png')

const ecommercePushHandle = () => {
    ecommercePush('click', {
        products: [
            {
                "id": props.card.id,
                "name": props.card.name,
                "price": props.card.discount_price ?? props.card.price,
                "category": props.card.category.name,
            }
        ]
    })
}
</script>

<style lang='scss' scoped>
.card-container {
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    background-color: var(--white);
    border-radius: 24px;
    box-shadow: 0px 4px 6px 0px #4D180D1A;
    transition: all 0.3s ease-in-out;
    color: #283647;

    &:hover {
        cursor: pointer;
        box-shadow: 0px 6px 24px 0px #4d180d94;
    }
}

.card-container-link {
    position: absolute;
    inset: 0;
    z-index: 0;
}

.annotation-container {
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;

    @supports not (--webkit-line-clamp: 2) {
        overflow: hidden;
        height: calc(13px * 6)
    }
}

.category {
    position: absolute;
    left: 10px;
    top: 10px;
    padding: 6px 10px 5px 10px;
    background-color: #090630;
    border: 1px solid #FFFFFF4D;
    border-radius: 29px;
    font-size: 12px;
    font-weight: 700;
    line-height: 80%;
    color: var(--white);
}

.favorite-img {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
    transition: transform 0.3s ease-in-out;
    z-index: 1;
    filter: invert(41%) sepia(37%) saturate(4564%) hue-rotate(345deg) brightness(87%) contrast(95%);

    &:hover {
        transform: scale(1.1);
        filter: invert(41%) sepia(37%) saturate(4564%) hue-rotate(345deg) brightness(87%) contrast(95%);
    }

}

.card-contents {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
    padding: 14px 15px 13px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-top: none;
    border-radius: 0 0 24px 24px;

    .title {
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;

        font-weight: 600;
        font-size: 18px;
        line-height: 120%;
        margin-bottom: 10px;
    }

    .count {
        margin-bottom: 3px;
        font-weight: 600;
        font-size: 13px;
        line-height: 100%;
        letter-spacing: -0.03em;
        color: #DA4727;
    }

    .extra-info {
        margin-bottom: 16px;
        font-weight: 600;
        font-size: 13px;
        line-height: 120%;
        letter-spacing: -0.03em;
        color: #DA4727;
		padding-top: 5px;
    }
}

.price {
    display: flex;
    gap: 6px;
    margin-bottom: 14px;

    .regular-price {
        font-weight: 700;
        font-size: 22px;
        line-height: 80%;
    }

    .discount-price {
        text-decoration: line-through;
        font-weight: 400;
        font-size: 16px;
        line-height: 80%;
        color: #9EADBF;
    }
}

.btn {
    text-align: center;
    background-color: #09063012;
    display: block;
    width: 100%;
    color: #090630;
    font-weight: 600;
    transition: all 0.3s ease-in-out;
    z-index: 1;

    &:hover {
        background-color: #090630;
        opacity: 0.8;
        color: white;
    }
}

.card-info {
    display: flex;
    flex-direction: column;
}

.top-img-box {
    position: relative;

    &::after {
        content: '';
        position: absolute;
        inset: 0;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 24px 24px 0 0;
    }
}

.top-img {
    width: 100%;
    object-fit: cover;
    object-position: center;
    aspect-ratio: 263 / 159;
}

@media (max-width:1023px) {
    .card-container {
        border-radius: 16px;
    }

    .category {
        left: 8px;
        top: 8px;
        font-size: 10px;

    }

    .card-contents {
        padding: 12px 10px 10px;
        border-radius: 0 0 16px 16px;

        .title {
            font-size: 15px;
            line-height: 115%;
        }

        .count {
            margin-bottom: 5px;
            font-weight: 400;
            font-size: 12px;
        }

        .extra-info {
            margin-bottom: 10px;
            font-size: 12px;
        }
    }

    .price {
        gap: 4px;
        margin-bottom: 12px;

        .regular-price {
            font-size: 18px;
        }

        .discount-price {
            font-size: 14px;
        }
    }

    .btn {
        font-size: 14px;
    }

    .top-img-box {
        &::after {
            border-radius: 16px 16px 0 0;
        }
    }
}
</style>
